import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './Header';
import Footer from './Footer';

const Layout = ({ props, children }) => {
  return (
    <>
      <Helmet>
        <html lang="en" />

        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
        </style>
      </Helmet>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
