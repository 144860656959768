import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Footer = (props) => {
  return (
    <footer className="bg-dark-blue">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          <div className="px-5 py-2">
            <a href="/" className="text-base text-gray-200 hover:text-gray-100">
              {' '}
              Home{' '}
            </a>
          </div>

          <div className="px-5 py-2">
            <a href="/explore" className="text-base text-gray-200 hover:text-gray-100">
              {' '}
              Explore{' '}
            </a>
          </div>

          <div className="px-5 py-2">
            <a href="https://airtable.com/shral2kRQj9MBTBiS" className="text-base text-gray-200 hover:text-gray-100">
              {' '}
              Contribute{' '}
            </a>
          </div>
        </nav>
        <p className="mt-8 text-center text-base text-gray-400">
          <span className="font-display">Neighbour Institute</span> | <span className="text-indigo-100">&copy;
          2022 Compassion International, Inc.</span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
