import React from 'react';
import Layout from './src/components/Layout';

import { UserContextProvider } from './src/lib/UserContext';

import './src/styles/helpers/_tailwind-directives.css';

export const wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
  <UserContextProvider>{element}</UserContextProvider>
);
