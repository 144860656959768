import React, { useState, createContext, useEffect } from 'react';
import { Magic } from 'magic-sdk';
// import { magic } from '../lib/magic';

let magic;

if (typeof window !== `undefined`) {
  magic = new Magic(process.env.GATSBY_MAGIC_PUBLISHABLE_API_KEY, {
    locale: navigator.language.split('-')[0],
  });
}

const UserContext = createContext(null);

const UserContextProvider = (props) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    magic.user.isLoggedIn().then((isLoggedIn) => {
      return isLoggedIn
        ? magic.user.getMetadata().then((userData) => setUser(userData))
        : setUser(null);
    });
  }, []);

  const handleLogout = async () => {
    await magic.user.logout();
    setUser(null);
  };

  const handleLogin = async (email, e) => {
    if (!email) return;

    try {
      // Login with Magic ✨
      await magic.auth.loginWithMagicLink({
        email,
      });

      let userMetadata = await magic.user.getMetadata();
      setUser(userMetadata);

      return user;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, handleLogin, handleLogout }}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
