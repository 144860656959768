import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import '../styles/components/header.scss';

import { UserContext } from '../lib/UserContext';

const Header = (props) => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const items = [
    {
      text: 'Home',
      uri: '/',
    },
    {
      text: 'Features',
      uri: '/#current-features',
    },
    {
      text: 'Roadmap',
      uri: '/#roadmap',
    },
    {
      text: 'Contribute',
      uri: '/#contribute',
    },
    {
      text: 'FAQ',
      uri: '/#faq',
    },
  ];

  return (
    <UserContext.Consumer>
      {(context) => {
        return (
          <header className="header relative py-8 border-bottom-100">
            <div className="nav__wrap mx-auto max-w-7xl px-4 sm:px-6">
              <nav className="text-sm">
                <Link to="/" className="nav__logo">
                  <span className="sr-only">Navigate to the home page</span>
                  <img
                    className="h-8 w-auto sm:h-14"
                    src="https://images.ctfassets.net/l60f8hxsa357/3PBXsZx5OtaL4L36Fpx1t0/61e2bdbdd8b1119c2a0e492d1a769959/Logo_Horizontal.svg"
                    alt="Neighbour Institute logo"
                  />
                </Link>
                <ul
                  className={`nav__items text-lg md:ml-4 md:inline-flex; absolute inset-x-0 top-full md:static shadow-lg md:shadow-none bg-white items-center ${
                    navOpen ? `nav__items-visible` : ``
                  }`}
                >
                  {items.map((item) => {
                    return (
                      <li
                        key={item.uri}
                        className="text-base font-medium text-gray-200 hover:text-gray-900"
                      >
                        <Link
                          className="block md:inline rounded-lg p-3 text-gray-500 hover:bg-gray-100 hover:text-slate-900"
                          to={item.uri}
                        >
                          {item.text}
                        </Link>
                      </li>
                    );
                  })}

                  <li className="md:flex-1 text-center md:text-right whitespace-nowrap md:px-1 md:mr-4 text-base font-medium text-gray-500 hover:text-gray-500">
                    {context && context.user ? (
                      <button onClick={context.handleLogout}>Sign out</button>
                    ) : (
                      <Link
                        state={{ modal: true }}
                        className="block md:inline rounded-lg p-3 py-4 font-medium text-gray-500 hover:bg-gray-200 hover:text-gray-500"
                        to="/signin"
                      >
                        Sign in
                      </Link>
                    )}
                  </li>
                </ul>
                <Link
                  to="/explore"
                  className="nav__cta inline-flex items-center px-3 py-2 border border-transparent text-base font-medium rounded-full text-primary bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Explore Content
                </Link>
                <div className="nav__button__wrap md:hidden">
                  <button
                    className="relative z-10 flex h-8 w-8 items-center justify-center [&amp;:not(:focus-visible)]:focus:outline-none"
                    id="headlessui-popover-button-:Rt6:"
                    type="button"
                    aria-expanded={navOpen}
                    onClick={toggleNav}
                  >
                    <span className="sr-only">Toggle Navigation</span>
                    <svg
                      aria-hidden="true"
                      className="h-3.5 w-3.5 overflow-visible text-slate-700"
                      fill="none"
                      strokeWidth="2"
                      strokeLinecap="round"
                    >
                      <path
                        stroke="currentColor"
                        d="M0 1H14M0 7H14M0 13H14"
                        className="origin-center transition"
                      ></path>
                      <path
                        stroke="currentColor"
                        d="M2 2L12 12M12 2L2 12"
                        className="origin-center transition scale-90 opacity-0"
                      ></path>
                    </svg>
                  </button>
                </div>
              </nav>
            </div>
          </header>
        );
      }}
    </UserContext.Consumer>
  );
};

export default Header;
